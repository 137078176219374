import React, { useEffect, useState, useCallback } from "react";
import withStyles from "react-jss";
import { useStoreon } from "storeon/react";

import { commonStyles, colors } from "services/theme";
import { _h, formatMentionText } from "services/utilities";
import { getTotalDiscoveredElementsByType, getCluesByElement } from "services/gameService";

const styles = {
  ...commonStyles,
  root: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: "100%",
    minHeight: "100vh",
    background: "rgba(0, 0, 0, 0.9)",
    overflowY: "auto",
    paddingBottom: "4rem"
  },
  topArea: {
    position: "relative",
    top: 0,
    left: 0,
    display: "flex",
    flexWrap: "wrap"
  },
  topAreaButton: {
    flexGrow: 1,
    border: `1px solid ${colors.light.main}`,
    padding: 20,
    outline: "none",
    background: colors.light.secondary,
    color: colors.light.main,
    fontSize: "1.4rem",
    cursor: "pointer"
  },
  selectedButton: {
    background: colors.red.main
  },
  tab: {
    padding: 20,
    textAlign: "left",
    fontSize: "0.9em"
  },
  popup: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflowY: "auto",
    background: "rgba(0,0,0,0.8)",
    fontSize: "1em",
    padding: "10px",
    paddingBottom: "4rem"
  },
  popupClue: {
    textAlign: "left",
    lineHeight: "1.4"
  },
  completed: {
    textDecoration: "line-through",
    opacity: 0.8
  }
};

export default withStyles(styles)(({ classes, setShowJournal }) => {
  /* eslint-disable no-unused-vars */
  const { dispatch, game } = useStoreon("game");
  const [tab, setTab] = useState(1);
  const [popup, setPopup] = useState(null);
  /* eslint-enable no-unused-vars */

  useEffect(() => {
    dispatch("ui/setLoading", false);

    return () => {
      dispatch("ui/setLoading", false);
    };
  }, [dispatch]);

  const totalDiscovered = useCallback(type => getTotalDiscoveredElementsByType(game, type), [game]);
  const getCluesFor = useCallback(
    el => getCluesByElement(game.state.journal.clues.concat(game.state.journal[game.thisPlayer.id].clues), el.id),
    [game.state.journal, game.thisPlayer.id]
  );

  const showInfo = (type, element) => {
    setPopup({ type, element });
  };

  const checkClicked = e => {
    if (e.target && e.target.attributes["data-id"] && e.target.attributes["data-type"]) {
      const id = e.target.attributes["data-id"].value;
      const type = e.target.attributes["data-type"].value;
      const element = game.story[type].find(e => e.id === id);
      setPopup({ type, element });
    }
  };

  const isCompleted = id => {
    return (
      game.state.journal &&
      game.state.journal.completes.concat(game.state.journal[game.thisPlayer.id].completes).find(c => c.id === id)
    );
  };

  return (
    <div className={classes.root}>
      <div className={classes.topArea}>
        <button onClick={() => setTab(1)} className={`${classes.topAreaButton} ${tab === 1 ? classes.selectedButton : ""}`}>
          Clues
        </button>
        <button onClick={() => setTab(2)} className={`${classes.topAreaButton} ${tab === 2 ? classes.selectedButton : ""}`}>
          Objectives
        </button>
        <button onClick={() => setTab(3)} className={`${classes.topAreaButton} ${tab === 3 ? classes.selectedButton : ""}`}>
          Intro
        </button>
        <button onClick={() => setTab(4)} className={`${classes.topAreaButton} ${tab === 4 ? classes.selectedButton : ""}`}>
          Locations
        </button>
        <button onClick={() => setTab(5)} className={`${classes.topAreaButton} ${tab === 5 ? classes.selectedButton : ""}`}>
          Characters
        </button>
        <button onClick={() => setTab(6)} className={`${classes.topAreaButton} ${tab === 6 ? classes.selectedButton : ""}`}>
          Topics
        </button>
      </div>

      {tab === 1 && (
        <div className={classes.tab}>
          {game.state &&
            game.state.journal.clues
              .concat(game.state.journal[game.thisPlayer.id].clues)
              .map((clue, ind) => (
                <p
                  key={`clues_${ind}`}
                  onClick={checkClicked}
                  style={{ borderBottom: "2px solid #3c3c3c", paddingBottom: 10 }}
                  dangerouslySetInnerHTML={_h(formatMentionText(clue.text))}
                ></p>
              ))}
        </div>
      )}
      {tab === 2 && (
        <div className={classes.tab}>
          {game.state &&
            game.state.journal.objectives
              .concat(game.state.journal[game.thisPlayer.id].objectives)
              .map((objective, ind) => (
                <p
                  key={`objectives_${ind}`}
                  className={isCompleted(objective.id) && classes.completed}
                  dangerouslySetInnerHTML={_h(formatMentionText(objective.display))}
                ></p>
              ))}
        </div>
      )}
      {tab === 3 && (
        <div className={classes.tab}>
          {game.story && game.story.intro.map((block, ind) => <p key={`intro_${ind}`}>{block.text}</p>)}
        </div>
      )}
      {tab === 4 && (
        <div className={`${classes.tab} ${classes.twoColumns}`}>
          {totalDiscovered("locations").map(el => (
            <button
              key={el.id}
              onClick={() => showInfo("locations", el)}
              className={`${classes.btnSecondary} ${classes.btn} ${classes.mb1}`}
            >
              {el.name}
            </button>
          ))}
        </div>
      )}
      {tab === 5 && (
        <div className={`${classes.tab} ${classes.twoColumns}`}>
          {totalDiscovered("characters").map(el => (
            <button
              key={el.id}
              onClick={() => showInfo("characters", el)}
              className={`${classes.btnSecondary} ${classes.btn} ${classes.mb1}`}
            >
              {el.name}
            </button>
          ))}
        </div>
      )}
      {tab === 6 && (
        <div className={`${classes.tab} ${classes.twoColumns}`}>
          {totalDiscovered("topics").map(el => (
            <button
              key={el.id}
              onClick={() => showInfo("topics", el)}
              className={`${classes.btnSecondary} ${classes.btn} ${classes.mb1}`}
            >
              {el.name}
            </button>
          ))}
        </div>
      )}

      {popup && (
        <div className={classes.popup}>
          <button className={`${classes.btn}`} onClick={() => setPopup(null)}>
            Close
          </button>
          <h4>{popup.element.name}</h4>
          <p>{popup.element.description}</p>
          <hr />
          {getCluesFor(popup.element).map((clue, ind) => (
            <p
              className={classes.popupClue}
              key={`cl_${popup.element.id}_${ind}`}
              onClick={checkClicked}
              dangerouslySetInnerHTML={_h(formatMentionText(clue.text))}
            ></p>
          ))}
        </div>
      )}
    </div>
  );
});
