import React from "react";
import withStyles from "react-jss";
import { useStoreon } from "storeon/react";

import { isActivePlayer } from "services/gameService";
import { commonStyles } from "services/theme";
import API from "services/api";

const styles = {
  ...commonStyles
};

export default withStyles(styles)(({ classes }) => {
  /* eslint-disable no-unused-vars */
  const { dispatch, game, auth } = useStoreon("game", "auth");
  /* eslint-enable no-unused-vars */

  return (
    <React.Fragment>
      {game.state && (
        <div>
          <h3>Location RECAP</h3>
          <h5>-Not yet implemented-</h5>
          {isActivePlayer(game, auth) && (
            <button
              onClick={() => API().message(dispatch, { uid: auth.user.uid, locationRecapAction: "continue" })}
              className={`${classes.margined1} ${classes.btn}`}
            >
              Continue
            </button>
          )}
        </div>
      )}
    </React.Fragment>
  );
});
