export default store => {
  store.on("@init", () => ({
    ui: {
      loading: false,
      appScreen: "Home",
      roomOptions: {
        id: null, // gameID for new, code for join
        method: null // one of join, create, continue (same as create?)
      }
    }
  }));

  store.on("ui/setLoading", ({ ui }, payload) => {
    const ret = { ...ui, loading: payload };
    return { ui: ret };
  });

  store.on("ui/setScreen", ({ ui }, payload) => {
    const ret = { ...ui, appScreen: payload };
    return { ui: ret };
  });

  store.on("ui/setRoomOptions", ({ ui }, payload) => {
    const ret = { ...ui, roomOptions: payload };
    return { ui: ret };
  });
};
