import React, { useState } from "react";
import withStyles from "react-jss";
import { useStoreon } from "storeon/react";

import { isActivePlayer, getCommonDiscoveredElementsByType, getPrivateDiscoveredElementsByType } from "services/gameService";

import { commonStyles } from "services/theme";
import API from "services/api";

const styles = {
  ...commonStyles,
  avatar: {
    width: 25,
    height: 25,
    borderRadius: "50%",
    verticalAlign: "middle"
  }
};

export default withStyles(styles)(({ classes }) => {
  /* eslint-disable no-unused-vars */
  const { dispatch, game, auth } = useStoreon("game", "auth");
  const [bestElements, setBestElements] = useState(null);
  /* eslint-enable no-unused-vars */

  const backToLocationSelect = () => {
    setBestElements(null);
    API().message(dispatch, { uid: auth.user.uid, selectLocation: null });
    return true;
  };

  const isSelectedButton = el => {
    return game.state.phaseData.locationElements.some(
      locEl => locEl.uid === auth.user.uid && locEl.element && locEl.element.id === el.id
    );
  };

  const selectedBy = id => {
    const sel = game.state.phaseData.locationElements.find(locEl => locEl.element && locEl.element.id === id);
    return sel ? game.story.players.find(p => p.id === sel.playerId) : false;
  };

  const selectElement = (el, type) => {
    if (elementSelectedPast(el.id)) {
      return false;
    }
    if (
      game.state.phaseData.locationElements &&
      !game.state.phaseData.location &&
      game.state.phaseData.locationElements.filter(e => e.element).length >= 2
    ) {
      window.scroll({ top: 0, left: 0, behavior: "smooth" });
    }
    API().message(dispatch, { uid: auth.user.uid, selectLocationElement: { ...el, type } });
  };

  const getAllUserElementsByType = ({ type, common }) => {
    const callFunction = common ? getCommonDiscoveredElementsByType : getPrivateDiscoveredElementsByType;
    return callFunction(game, type).map(el => (
      <button
        key={el.id}
        onClick={() => selectElement(el, type)}
        className={`${classes.btn} ${classes.btnSecondary} ${classes.mb1} ${isSelectedButton(el) ? classes.btnActive : ""} ${
          elementSelectedPast(el.id) ? classes.btnInactive : ""
        }`}
      >
        {el.name}{" "}
        {selectedBy(el.id) && <img className={classes.avatar} src={selectedBy(el.id).asset.file} alt={selectedBy(el.id).name} />}
      </button>
    ));
  };

  const visitLocation = () => {
    setBestElements(null);
    API().message(dispatch, { uid: auth.user.uid, visitLocation: game.state.phaseData.selectedLocation });
  };

  const getBestElements = async () => {
    const best = await API().post("/bestElements", { uid: auth.user.uid });
    console.log(best);
    setBestElements(best);
  };

  const elementSelectedPast = id => {
    // probably dont need type === location also, id is enough
    const allAskedHere = game.state.history
      .filter(h => h.element === game.state.phaseData.selectedLocation)
      .map(past => past.asked.map(a => a.id))
      .reduce((all, item) => all.concat(item), []);
    return allAskedHere.includes(id);
  };

  return (
    <React.Fragment>
      {game.state.phaseData.confirmLocation &&
        game.state.phaseData.locationElements &&
        game.state.phaseData.locationElements.find(p => p.uid === auth.user.uid) && (
          <React.Fragment>
            {isActivePlayer(game, auth) && (
              <div>
                <hr />
                <button onClick={backToLocationSelect} className={`${classes.margined1} ${classes.btn}`}>
                  Back to Map
                </button>
                {game.state.phaseData.locationElements.filter(e => e.element).length === 3 && (
                  <button onClick={visitLocation} className={`${classes.margined1} ${classes.btn}`}>
                    Visit Location
                  </button>
                )}
              </div>
            )}

            <button onClick={getBestElements} className={`${classes.margined1} ${classes.btn}  ${classes.btnSmall}`}>
              Intuition
            </button>
            {bestElements && bestElements.length > 0 && (
              <div>
                You should ask about {bestElements[0] && bestElements[0].element.name}
                {bestElements[1] && ` and ${bestElements[1].element.name}`}
              </div>
            )}
            {bestElements && bestElements.length === 0 && <div>Nothing special to ask about</div>}
            <h4>Ask about:</h4>
            <p>Common Topics:</p>
            <div className={classes.twoColumns}>{getAllUserElementsByType({ type: "topics", common: true })}</div>
            <p>Common Characters:</p>
            <div className={classes.twoColumns}>{getAllUserElementsByType({ type: "characters", common: true })}</div>

            <p>Private Topics:</p>
            <div className={classes.twoColumns}>{getAllUserElementsByType({ type: "topics", common: false })}</div>
            <p>Private Characters:</p>
            <div className={classes.twoColumns}>{getAllUserElementsByType({ type: "characters", common: false })}</div>
          </React.Fragment>
        )}
    </React.Fragment>
  );
});
