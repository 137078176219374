import React, { useState } from "react";
import withStyles from "react-jss";
import { useStoreon } from "storeon/react";

import { commonStyles } from "services/theme";
import API from "services/api";

const styles = {
  ...commonStyles
};

export default withStyles(styles)(({ classes }) => {
  /* eslint-disable no-unused-vars */
  const [step, setStep] = useState(0);
  const [isWaiting, setIsWaiting] = useState(false);
  const { dispatch, game, auth } = useStoreon("game", "auth");
  /* eslint-enable no-unused-vars */

  const clickNext = () => {
    const nextStep = step + 1;
    if (nextStep >= game.thisPlayer.preIntro.length) {
      setIsWaiting(true);
      API().message(dispatch, { uid: auth.user.uid, preIntroFinished: true });
    } else {
      setStep(nextStep);
    }
  };

  return (
    <React.Fragment>
      <div className={`${classes.innerContainer2}`}>
        <h1 className={`${classes.bigHeader} ${classes.mt1} ${classes.mb2}`}>Pre Intro</h1>
        {!isWaiting && (
          <div className={classes.textLeft}>
            <div className={classes.mb2}>
              {game.thisPlayer && game.thisPlayer.preIntro[step] && game.thisPlayer.preIntro[step].text}
            </div>
            <button onClick={clickNext} className={`${classes.margined1} ${classes.btn}`}>
              Continue
            </button>
          </div>
        )}
        {isWaiting && <h2>Waiting for other players.</h2>}
      </div>
    </React.Fragment>
  );
});
