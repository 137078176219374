import React, { useState, useEffect } from "react";
import withStyles from "react-jss";
import { useStoreon } from "storeon/react";

import Lobby from "modules/Main/Game/Lobby/Lobby";
import PreIntro from "modules/Main/Game/Intro/PreIntro";
import Intro from "modules/Main/Game/Intro/Intro";
import PostIntro from "modules/Main/Game/Intro/PostIntro";
import EventIntro from "modules/Main/Game/Event/EventIntro";
import EventChoices from "modules/Main/Game/Event/EventChoices";
import EventChoiceResult from "modules/Main/Game/Event/EventChoiceResult";
import EventRecap from "modules/Main/Game/Event/EventRecap";
import LocationSelect from "modules/Main/Game/LocationSelect/LocationSelect";
import LocationVisit from "modules/Main/Game/LocationVisit/LocationVisit";
import LocationRecap from "modules/Main/Game/LocationVisit/LocationRecap";
import Journal from "modules/Main/Game/Journal/Journal";

import { joinRoom, isCreator } from "services/gameService";
import { updateRoomCode } from "services/dbService";
import { commonStyles, colors } from "services/theme";
import API from "services/api";

const styles = {
  ...commonStyles,
  btnJournal: {
    position: "fixed",
    bottom: 15,
    left: 15,
    border: "none",
    outline: "none",
    width: 50,
    height: 50,
    padding: 8,
    cursor: "pointer",
    background: colors.red.main,
    borderRadius: "50%",
    zIndex: 999
  },
  content: {
    paddingBottom: "5rem"
  }
};

export default withStyles(styles)(({ classes, setGameCode }) => {
  const [userIsCreator, setUserIsCreator] = useState(false);
  const [isLobby, setIsLobby] = useState(true);
  const [init, setInit] = useState(false);
  const [showJournal, setShowJournal] = useState(false);
  const [code, setCode] = useState(null);
  const [timer, setTimer] = useState(null);
  const { dispatch, ui, game, auth } = useStoreon("ui", "game", "auth");

  useEffect(() => {
    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  useEffect(() => {
    setIsLobby(
      (game.state && game.story && !game.state.started) ||
        (game.state &&
          game.state.started &&
          game.state.activePlayers &&
          game.state.selectedPlayers &&
          game.state.activePlayers.length !== game.state.selectedPlayers.length)
    );
  }, [setIsLobby, game.state, game.story]);

  const initGame = () => {
    if (ui.roomOptions.id && ui.roomOptions.method) {
      dispatch("ui/setLoading", true);
      let creating = ui.roomOptions.method && ui.roomOptions.method === "create" ? true : false;

      joinRoom(creating, ui.roomOptions.id, auth.user).then(newState => {
        console.log(newState);
        console.log("joined");

        if (newState.message) {
          dispatch("ui/setLoading", false);
          dispatch("error/setError", {
            visible: true,
            text: newState.message
          });
          return false;
        }

        dispatch("game/setState", { newState, uid: auth.user.uid });
        let checkCreator = false;
        if (newState.meta) {
          checkCreator = isCreator(newState, auth);
          setUserIsCreator(checkCreator);
        }

        if (creating) {
          creating = false;
          updateRoomCode(ui.roomOptions.id)
            .then(code => {
              setCode(code);
              if (checkCreator) {
                setGameCode(code);
              }
              setInit(true);
              // Add auto-updates for new states
              setUpdateTimer();
              dispatch("ui/setLoading", false);
            })
            .catch(error => {
              dispatch("ui/setLoading", false);
              dispatch("error/setError", {
                visible: true,
                text: error.message
              });
            });
        } else {
          setInit(true);
          // Add auto-updates for new states
          setUpdateTimer();
          dispatch("ui/setLoading", false);
        }
      });

      /*onError: error => {
        console.log("error:", error);
        dispatch("ui/setLoading", false);
        dispatch("error/setError", {
          visible: true,
          text: JSON.stringify(error)
        });
      }*/
    }
  };

  const setUpdateTimer = () => {
    setTimer(
      setInterval(async () => {
        const newState = await API().post("/state", { uid: auth.user.uid });
        dispatch("game/setState", { newState: newState.currentState, uid: auth.user.uid });
      }, 6000)
    );
  };

  return (
    <div className={`${classes.bgGame}`}>
      {!init && (
        <div className={`${classes.innerContainer} ${classes.flexRow}`}>
          <h2>Connect to Game</h2>
          <button onClick={initGame} className={`${classes.btn}`}>
            CONNECT
          </button>
        </div>
      )}

      {init && isLobby && <Lobby code={code} userIsCreator={userIsCreator} />}

      {init && !isLobby && (
        <React.Fragment>
          <button onClick={() => setShowJournal(j => !j)} className={classes.btnJournal}>
            <img src="/images/journal.svg" alt="" />
          </button>
          {game.state && showJournal && <Journal setShowJournal={setShowJournal} />}

          {game.state && !showJournal && (
            <div className={classes.content}>
              {game.state.phase === "PreIntro" && <PreIntro />}
              {game.state.phase === "Intro" && <Intro userIsCreator={userIsCreator} />}
              {game.state.phase === "PostIntro" && <PostIntro />}
              {game.state.phase === "EventIntro" && <EventIntro userIsCreator={userIsCreator} />}
              {game.state.phase === "EventChoices" && <EventChoices userIsCreator={userIsCreator} />}
              {game.state.phase === "EventChoiceResult" && <EventChoiceResult userIsCreator={userIsCreator} />}
              {game.state.phase === "EventRecap" && <EventRecap userIsCreator={userIsCreator} />}
              {game.state.phase === "LocationSelect" && <LocationSelect />}
              {game.state.phase === "LocationVisit" && <LocationVisit />}
              {game.state.phase === "LocationRecap" && <LocationRecap />}
            </div>
          )}
        </React.Fragment>
      )}
    </div>
  );
});
