import React, { useState, useEffect, useRef } from "react";
import withStyles from "react-jss";
import { useSprings, animated } from "react-spring";
import { useDrag } from "react-use-gesture";

import { commonStyles } from "services/theme";

const styles = {
  ...commonStyles,
  root: {
    position: "relative",
    overflow: "hidden",
    width: "100%",
    userSelect: "none",
    height: "40vh",
    minHeight: "400px"
  },
  div1: {
    position: "absolute",
    top: 0,
    left: 0,
    willChange: "transform",
    height: "40vh",
    minHeight: "400px",
    overflow: "auto",
    margin: "0 0.5%"
  },
  div2: {
    willChange: "transform",
    boxShadow: "0 62.5px 125px -25px rgba(50, 50, 73, 0.5), 0 37.5px 75px -37.5px rgba(0, 0, 0, 0.6)"
  }
};

export default withStyles(styles)(({ classes, slides, perPage = 1 }) => {
  const [slideWidth, setSlideWidth] = useState(window.innerWidth / perPage);
  const rootEl = useRef(null);
  const index = useRef(0);
  const [carousel, setCarousel] = useSprings(slides.length, i => ({ x: i * slideWidth, sc: 1, display: "block" }));

  useEffect(() => {
    const newWidth = rootEl.current.clientWidth / perPage;
    setSlideWidth(newWidth);
    setCarousel(i => ({ x: i * newWidth, sc: 1, display: "block" }));
  }, [perPage, setCarousel]);

  const bind = useDrag(({ down, delta: [xDelta], direction: [xDir], distance, cancel }) => {
    if (down && distance > slideWidth / 3) {
      let cur = index.current + (xDir > 0 ? -1 : 1);
      if (cur < 0) {
        cur = 0;
      }
      if (cur > slides.length - 1) {
        cur = slides.length - 1;
      }
      cancel((index.current = cur));
    }
    setCarousel(i => {
      if (i < index.current - perPage || i > index.current + perPage) return { display: "none" };
      const x = (i - index.current) * slideWidth + (down ? xDelta : 0);
      const sc = down ? 1 - distance / slideWidth / 3 : 1;
      return { x, sc, display: "block" };
    });
  });

  return (
    <div className={classes.root} ref={rootEl}>
      {carousel.map(({ x, display, sc }, i) => (
        <animated.div
          {...bind()}
          key={i}
          className={classes.div1}
          style={{ display, width: `${100 / perPage - 1}%`, transform: x.interpolate(x => `translateX(${x}px)`) }}
        >
          <animated.div className={classes.div2} style={{ transform: sc.interpolate(s => `scale(${s})`) }}>
            {slides[i]}
          </animated.div>
        </animated.div>
      ))}
      <div style={{ clear: "both" }}></div>
    </div>
  );
});
