import React from "react";
import withStyles from "react-jss";
import { useStoreon } from "storeon/react";

import { useInputValue } from "services/hooks";
import { getRoomFromCode } from "services/dbService";

import { commonStyles } from "services/theme";

const styles = {
  ...commonStyles
};

export default withStyles(styles)(({ classes }) => {
  const { ...gameCode } = useInputValue("");
  const { dispatch } = useStoreon();

  const userJoinGame = code => {
    dispatch("ui/setLoading", true);
    getRoomFromCode(code)
      .then(gameID => {
        dispatch("ui/setLoading", false);
        dispatch("ui/setRoomOptions", { id: gameID, method: "join" });
        dispatch("ui/setScreen", "Game");
      })
      .catch(error => {
        dispatch("ui/setLoading", false);
        dispatch("error/setError", { visible: true, text: error.message });
      });
  };

  return (
    <React.Fragment>
      <div className={`${classes.innerContainer} ${classes.mb2}`}>
        <h1 className={`${classes.bigHeader} ${classes.mt1} ${classes.mb1}`}>Enter a code to join a game in progress</h1>

        <input {...gameCode} id="gameCode" name="gameCode" autoComplete="gameCode" autoFocus required maxLength={5} />

        <button onClick={() => userJoinGame(gameCode.value)} className={`${classes.btn} ${classes.mt2} ${classes.mb2}`}>
          Join
        </button>
        <button onClick={() => dispatch("ui/setScreen", "Home")} className={`${classes.btn} ${classes.btnSmall} ${classes.mt2}`}>
          Back to Home
        </button>
      </div>
    </React.Fragment>
  );
});
