const colors = {
  players: { main: "#584976", secondary: "#eee" },
  topics: { main: "#486d8d", secondary: "#eee" },
  characters: { main: "#9d9246", secondary: "#eee" },
  locations: { main: "#709754", secondary: "#eee" },
  events: { main: "#9e5454", secondary: "#eee" },
  elements: { main: "#cd5749", secondary: "#eee" },
  clues: { main: "#1b7a60", secondary: "#eee" },
  objectives: { main: "#ca6b33", secondary: "#eee" },
  green: { main: "#709754", secondary: "#eee" },
  red: { main: "#c72f40", secondary: "#eee" },
  white: { main: "#eee", secondary: "#333" },
  light: { main: "#cacac0", secondary: "#2d2d2d" },
  secondary: { main: "#0a4e45", secondary: "#eee" }
};

const borderWidth = 3;

const textColors = {
  elementsTxt: {
    color: colors.elements.main
  },
  playersTxt: {
    color: colors.players.main
  },
  topicsTxt: {
    color: colors.topics.main
  },
  charactersTxt: {
    color: colors.characters.main
  },
  locationsTxt: {
    color: colors.locations.main
  },
  eventsTxt: {
    color: colors.events.main
  },
  objectivesTxt: {
    color: colors.objectives.main
  },
  cluesTxt: {
    color: colors.clues.main
  },
  greenTxt: {
    color: colors.green.main
  },
  redTxt: {
    color: colors.red.main
  },
  whiteTxt: {
    color: colors.white.main
  }
};

const bgColors = {
  elementsBg: {
    color: colors.elements.secondary,
    backgroundColor: colors.elements.main
  },
  elementsBorder: {
    border: `${borderWidth}px solid ${colors.elements.main}`
  },
  playersBg: {
    color: colors.players.secondary,
    backgroundColor: colors.players.main
  },
  playersBorder: {
    border: `${borderWidth}px solid ${colors.players.main}`
  },
  topicsBg: {
    color: colors.topics.secondary,
    backgroundColor: colors.topics.main
  },
  topicsBorder: {
    border: `${borderWidth}px solid ${colors.topics.main}`
  },
  charactersBg: {
    color: colors.characters.secondary,
    backgroundColor: colors.characters.main
  },
  charactersBorder: {
    border: `${borderWidth}px solid ${colors.characters.main}`
  },
  locationsBg: {
    color: colors.locations.secondary,
    backgroundColor: colors.locations.main
  },
  locationsBorder: {
    border: `${borderWidth}px solid ${colors.locations.main}`
  },
  eventsBg: {
    color: colors.events.secondary,
    backgroundColor: colors.events.main
  },
  eventsBorder: {
    border: `${borderWidth}px solid ${colors.events.main}`
  },
  cluesBg: {
    color: colors.clues.secondary,
    backgroundColor: colors.clues.main
  },
  cluesBorder: {
    border: `${borderWidth}px solid ${colors.clues.main}`
  },
  objectivesBg: {
    color: colors.objectives.secondary,
    backgroundColor: colors.objectives.main
  },
  objectivesBorder: {
    border: `${borderWidth}px solid ${colors.objectives.main}`
  },
  redBg: {
    color: colors.red.secondary,
    backgroundColor: colors.red.main
  },
  redBorder: {
    border: `${borderWidth}px solid ${colors.red.main}`
  },
  greenBg: {
    color: colors.green.secondary,
    backgroundColor: colors.green.main
  },
  greenBorder: {
    border: `${borderWidth}px solid ${colors.green.main}`
  },
  whiteBg: {
    color: colors.white.secondary,
    backgroundColor: colors.white.main
  },
  whiteBorder: {
    border: `${borderWidth}px solid ${colors.white.main}`
  }
};

const mentionStyles = {
  control: {
    backgroundColor: "#fff",
    fontSize: 12,
    fontWeight: "normal"
  },

  highlighter: {
    overflow: "hidden",
    letterSpacing: 0,
    lineHeight: 1.5,
    textAlign: "left"
  },

  input: {
    margin: 0,
    letterSpacing: 0,
    lineHeight: 1.5,
    textAlign: "left"
  },

  "&singleLine": {
    control: {
      display: "inline-block",
      width: 130
    },

    highlighter: {
      padding: 1,
      border: "2px inset transparent"
    },

    input: {
      padding: 1,
      border: "2px inset"
    }
  },

  "&multiLine": {
    control: {
      border: "none",
      borderBottom: "1px solid white",
      backgroundColor: "transparent",
      fontSize: "1.2em"
    },

    highlighter: {
      padding: 9,
      minHeight: 30,
      outline: 0,
      border: 0
    },

    input: {
      padding: 9,
      minHeight: 30,
      outline: 0,
      border: 0,
      color: "white"
    }
  },

  suggestions: {
    list: {
      maxHeight: 100,
      overflow: "auto",
      position: "absolute",
      bottom: 14,
      backgroundColor: "white",
      border: "1px solid rgba(0,0,0,0.15)",
      fontSize: 10
    },

    item: {
      padding: "5px 15px",
      borderBottom: "1px solid rgba(0,0,0,0.15)",
      backgroundColor: "#222",
      color: "#fff",
      "&focused": {
        backgroundColor: "#444"
      }
    }
  }
};

const commonStyles = {
  container: {
    position: "relative",
    maxWidth: 1080,
    margin: "0 auto",
    padding: "10px",
    minHeight: "100vh",
    fontWeight: 300
  },
  innerContainer: {
    maxWidth: "80vw",
    margin: "0 10vw"
  },
  innerContainer2: {
    maxWidth: "90vw",
    margin: "0 5vw"
  },
  flexRow: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    minHeight: "100vh"
  },
  flexGrow: {
    flexGrow: 1
  },
  bgMenu: {
    background: `url('${process.env.PUBLIC_URL}/images/bg_login.jpg') no-repeat center`,
    backgroundSize: "cover"
  },
  bgGame: {
    background: `url('${process.env.PUBLIC_URL}/images/bg_game.jpg') no-repeat center`,
    backgroundSize: "cover"
  },
  bigHeader: {
    fontSize: "3rem",
    fontWeight: 300
  },
  textLeft: {
    textAlign: "left"
  },
  smaller: {
    fontSize: "0.8em"
  },
  marginAuto: {
    margin: "0 auto"
  },
  margined1: {
    marginTop: "1em",
    marginBottom: "1em"
  },
  margined2: {
    margin: "2em"
  },
  mt1: { marginTop: "1em" },
  mt2: { marginTop: "2em" },
  mt3: { marginTop: "3em" },
  mr1: { marginRight: "1em" },
  mr2: { marginRight: "2em" },
  mb1: { marginBottom: "1em" },
  mb2: { marginBottom: "2em" },
  mb3: { marginBottom: "3em" },
  ml1: { marginLeft: "1em" },
  ml2: { marginLeft: "2em" },
  pt1: { paddingTop: "1em" },
  pt2: { paddingTop: "2em" },
  pb1: { paddingBottom: "1em" },
  pb2: { paddingBottom: "2em" },

  noscroll: { overflow: "hidden" },
  fontLarger1: {
    fontSize: "1.5em"
  },
  link: {
    display: "inline-block",
    outline: "none !important",
    border: "none",
    background: "none",
    cursor: "pointer",
    color: colors.red.main,
    fontSize: "inherit"
  },
  block: {
    display: "block"
  },
  btn: {
    display: "block",
    width: "100%",
    border: "none",
    outline: "none !important",
    textAlign: "center",
    fontSize: "1em",
    cursor: "pointer",
    borderRadius: "30px",
    padding: "5px 15px",
    fontWeight: 300,
    lineHeight: 1,
    background: colors.red.main,
    color: colors.red.secondary
  },
  btnSecondary: {
    background: colors.secondary.main,
    color: colors.secondary.secondary
  },
  btnSmall: {
    width: "auto",
    fontSize: "0.7em",
    display: "inline-block"
  },
  btn80: {
    maxWidth: "80%",
    marginLeft: "auto",
    marginRight: "auto"
  },

  btnActive: {
    color: colors.red.secondary,
    borderColor: colors.red.secondary,
    background: colors.red.main
  },
  btnInactive: {
    opacity: 0.8,
    color: "#aaa !important",
    borderColor: "#aaa !important",
    background: "#444 !important",
    cursor: "default"
  },
  bgDark: {
    background: "#333"
  },
  fullWidth: {
    width: "100%",
    marginLeft: "0",
    marginRight: 0
  },
  box: {
    padding: "0.5em 0.1em",
    border: "1px solid #eee"
  },
  twoColumns: {
    columnCount: 2
  }
};

export { colors, textColors, bgColors, mentionStyles, commonStyles };
