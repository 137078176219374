import React from "react";
import withStyles from "react-jss";
import { useStoreon } from "storeon/react";

import { commonStyles } from "services/theme";

const styles = {
  ...commonStyles,
  privateBlock: {
    background: "#1f1818",
    padding: 10,
    marginBottom: 30
  }
};

export default withStyles(styles)(({ classes, blockParent, blockIndex, isPrivate = false }) => {
  /* eslint-disable no-unused-vars */
  const { game } = useStoreon("game");
  /* eslint-enable no-unused-vars */

  return (
    <div className={`${isPrivate ? classes.privateBlock : ""} ${classes.textLeft}`}>
      {isPrivate && <h3>PRIVATE</h3>}
      <div className={classes.mb2}>
        <em>{blockParent.blocks[blockIndex].introTxt}</em>
      </div>
      {blockParent.blocks[blockIndex].characterID && (
        <div className={classes.mb1}>
          <hr />
          {game.story.characters.find(c => c.id === blockParent.blocks[blockIndex].characterID).name}
        </div>
      )}
      <div className={classes.mb2}>{blockParent.blocks[blockIndex].mainTxt}</div>
    </div>
  );
});
