import shortid from "shortid";
import { Firebase, FirebaseAuth, dbUsers, dbPublished, dbRoomCodes } from "services/firebase";
import API from "services/api";
import { randFromTo } from "services/utilities";

const AuthCheck = () => {
  return new Promise((resolve, reject) => {
    FirebaseAuth.onAuthStateChanged(user => {
      if (user) {
        resolve(user);
      } else {
        resolve(false);
      }
    });
  });
};

const AuthSignin = user => {
  return new Promise((resolve, reject) => {
    if (user) {
      FirebaseAuth.signInWithEmailAndPassword(user.email, user.password)
        .then(usr => {
          resolve(usr);
        })
        .catch(error => {
          reject(error);
        });
    } else {
      reject({ message: "Please enter your email and password" });
    }
  });
};

const AuthSignup = user => {
  return new Promise((resolve, reject) => {
    if (user) {
      FirebaseAuth.createUserWithEmailAndPassword(user.email, user.password)
        .then(usr => {
          const uid = usr.user.uid;
          const now = new Date();
          dbUsers
            .doc(uid)
            .set({
              userID: uid,
              email: user.email,
              createdAt: now,
              updatedAt: now
            })
            .then(() => {
              resolve(true);
            })
            .catch(error => {
              reject(error);
            });
        })
        .catch(error => {
          reject(error);
        });
    } else {
      reject("No user");
    }
  });
};

const AuthLogout = () => {
  FirebaseAuth.signOut()
    .then(() => {
      window.location.reload();
    })
    .catch(error => {
      console.error(error);
    });
};

const getUser = () => {
  return new Promise((resolve, reject) => {
    const uid = FirebaseAuth.currentUser.uid;
    dbUsers
      .doc(uid)
      .get()
      .then(profile => {
        const currentUser = profile.data();
        resolve(currentUser);
      })
      .catch(error => {
        reject(error);
        console.error(error);
      });
  });
};

const getPublishedStories = () => {
  return new Promise((resolve, reject) => {
    dbPublished
      .get()
      .then(stories => {
        const arr = [];
        if (!stories.empty) {
          stories.docs.forEach(story => {
            const el = story.data();
            el.id = story.id;
            arr.push(el);
          });
        }
        resolve(arr);
      })
      .catch(error => {
        reject(error);
        console.error(error);
      });
  });
};

const getPublishedStory = uid => {
  return new Promise((resolve, reject) => {
    dbPublished
      .doc(uid)
      .get()
      .then(story => {
        const el = story.data();
        el.id = story.id;
        resolve(el);
      })
      .catch(error => {
        reject(error);
        console.error(error);
      });
  });
};

const getCreatedGames = uid => {
  return new Promise((resolve, reject) => {
    dbUsers
      .doc(uid)
      .get()
      .then(async usr => {
        const el = usr.data();
        const gamesAll = el.createdGames;
        /*const games = Promise.all(
          gamesAll.map(async game => {
            const details = await dbPublished.doc(game.storyId).get();
            game.details = details.data();
            return game;
          })
        );*/
        let games = [];
        if (gamesAll && gamesAll.length > 0) {
          games = Promise.all(
            gamesAll.map(async game => {
              const details = await API().post("/getGame", { gameID: game.gameID });
              game.details = details;
              return game;
            })
          );
        }
        resolve(games);
      })
      .catch(error => {
        reject(error);
        console.error(error);
      });
  });
};

const createNewGame = (uid, data) => {
  return new Promise((resolve, reject) => {
    dbUsers
      .doc(uid)
      .get()
      .then(usr => {
        const userData = usr.data();
        if (userData.createdGames && userData.createdGames.length >= 5) {
          reject({
            error: true,
            message: "You have already created 5 active games. Please complete or remove some and try again."
          });
          return false;
        }
        shortid.characters("0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNPQRSTUVWXYZ-+*");
        const newid = shortid.generate();
        console.log(data);
        const dataJSON = JSON.stringify(data);

        const user = {
          uid: uid,
          email: userData.email,
          displayName: userData.displayName,
          photoURL: userData.photoURL,
          roomid: null
        };
        console.log(user);
        API().post("/login", user).then(tokenData => {
          console.log(tokenData);
          API().setToken(tokenData.token);

          console.log("CREATE");
          API().post("/createGame", { newid, dataJSON }).then(res => {
            console.log(res);
            const newGame = {
              gameID: newid,
              storyId: data.story.id,
              storyName: data.story.name
            };
            dbUsers.doc(uid).update({
              createdGames: Firebase.firestore.FieldValue.arrayUnion(newGame)
            });
            resolve(newid);
          })
          .catch(error => {
            reject(error);
            console.error(error);
          });
        })
        .catch(error => {
          reject(error);
          console.error(error);
        });

      });
  });
};

const deleteExistingGame = (uid, gameID) => {
  return new Promise((resolve, reject) => {
    dbUsers
      .doc(uid)
      .get()
      .then(usr => {
        const userData = usr.data();
        if (userData.createdGames && userData.createdGames.find(g => g.gameID === gameID)) {
          const newGames = userData.createdGames.filter(g => g.gameID !== gameID);
          dbUsers.doc(uid).update({ createdGames: newGames });
          dbRoomCodes.doc(gameID).delete();
          API().post("/removeGame", { gameID });
          resolve(true);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
};

const updateRoomCode = gameID => {
  return new Promise((resolve, reject) => {
    dbRoomCodes
      .doc(gameID)
      .get()
      .then(room => {
        let code = null;
        if (room.exists) {
          code = room.data().code;
        } else {
          let exists = true;
          const checkFunc = async c => {
            const ex = await codeExists(c);
            return ex;
          };
          // generate unique code to send to user for joining
          let i = 0;
          while (exists === true && i < 20) {
            code = randFromTo(10000, 99999).toString();
            exists = checkFunc(code);
            i++;
          }
          if (i >= 20) {
            reject({ message: "Too many attempts to create game." });
            console.error({ message: "Too many attempts to create game." });
            return false;
          }
        }
        dbRoomCodes
          .doc(gameID)
          .set({ code, gameID })
          .then(res => {
            resolve(code);
          })
          .catch(error => {
            reject(error);
            console.error(error);
          });
      })
      .catch(error => {
        reject(error);
        console.error(error);
      });
  });
};

const codeExists = code => {
  return new Promise((resolve, reject) => {
    dbRoomCodes
      .where("code", "==", code)
      .get()
      .then(res => {
        if (!res.empty) {
          resolve(true);
        } else {
          resolve(false);
        }
      })
      .catch(error => {
        reject(error);
        console.error(error);
      });
  });
};

const getRoomFromCode = code => {
  return new Promise((resolve, reject) => {
    dbRoomCodes
      .where("code", "==", code)
      .get()
      .then(res => {
        if (!res.empty) {
          const data = res.docs[0].data();
          resolve(data.gameID);
        } else {
          reject({ message: "Wrong code" });
        }
      })
      .catch(error => {
        reject(error);
        console.error(error);
      });
  });
};

export {
  AuthCheck,
  AuthSignin,
  AuthSignup,
  AuthLogout,
  getUser,
  getPublishedStories,
  getPublishedStory,
  getCreatedGames,
  createNewGame,
  deleteExistingGame,
  updateRoomCode,
  getRoomFromCode
};
