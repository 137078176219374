import React, { useState } from "react";
import withStyles from "react-jss";
import { useStoreon } from "storeon/react";

import { isActivePlayer, getTotalDiscoveredElementsByType } from "services/gameService";
import LocationSelectElements from "./LocationSelectElements";

import { commonStyles } from "services/theme";
import API from "services/api";

const styles = {
  ...commonStyles
};

export default withStyles(styles)(({ classes }) => {
  /* eslint-disable no-unused-vars */
  const { dispatch, game, auth } = useStoreon("game", "auth");
  const [bestLocation, setBestLocation] = useState(null);
  /* eslint-enable no-unused-vars */

  const clickSelectLocation = id => {
    setBestLocation(null);
    dispatch("ui/setLoading", true);
    API().message(dispatch, { uid: auth.user.uid, selectLocation: id });
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    // Add a loading to sync with TV/Main animation duration
    setTimeout(() => {
      dispatch("ui/setLoading", false);
    }, 2000);
  };

  const getBestLocation = async () => {
    const locs = await API().post("/bestLocations", { uid: auth.user.uid });
    console.log(locs);
    setBestLocation(locs.best.clues);
  };

  return (
    <React.Fragment>
      {game.state && (
        <React.Fragment>
          <h3>LOCATION SELECT</h3>

          {bestLocation && (
            <div>
              You should try to visit {bestLocation[0] && bestLocation[0].name} {bestLocation[1] && ` or ${bestLocation[1].name}`}
            </div>
          )}

          {isActivePlayer(game, auth) && !game.state.phaseData.confirmLocation && (
            <React.Fragment>
              <button onClick={getBestLocation} className={`${classes.margined1} ${classes.btn}  ${classes.btnSmall}`}>
                Investigation
              </button>
              {game.state.phaseData.selectedLocation && (
                <button
                  onClick={() => API().message(dispatch, { uid: auth.user.uid, confirmLocation: true })}
                  className={`${classes.margined1} ${classes.btn} ${classes.fullWidth}`}
                >
                  Visit Location
                </button>
              )}
              <div className={classes.twoColumns}>
                {getTotalDiscoveredElementsByType(game, "locations").map(l => (
                  <button
                    key={l.id}
                    onClick={() => clickSelectLocation(l.id)}
                    className={`${classes.btn} ${classes.btnSecondary} ${classes.mb1} ${
                      game.state.phaseData.selectedLocation === l.id ? classes.btnActive : ""
                    }`}
                  >
                    {l.name}
                  </button>
                ))}
              </div>
            </React.Fragment>
          )}

          <LocationSelectElements />
        </React.Fragment>
      )}
    </React.Fragment>
  );
});
