import React, { useState } from "react";

import AuthLogin from "modules/AuthLogin/AuthLogin";
import AuthRegister from "modules/AuthRegister/AuthRegister";

export default () => {
  /* eslint-disable no-unused-vars */
  const [showRegister, setShowRegister] = useState(false);
  /* eslint-enable no-unused-vars */

  return (
    <div className="auth-wrapper">
      <div>
        {showRegister ? <AuthRegister setShowRegister={setShowRegister} /> : <AuthLogin setShowRegister={setShowRegister} />}
      </div>
    </div>
  );
};
