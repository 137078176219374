import API from "services/api";

// id is gameID for create, roomID in server for join
const joinRoom = async (isNew, id, userData) => {
  console.log(`Joining ${isNew ? "new" : "existing"} room for: `, id);

  const user = {
    uid: userData.uid,
    email: userData.email,
    displayName: userData.displayName,
    photoURL: userData.photoURL,
    roomid: id
  };

  const tokenData = await API().post("/login", user);
  await API().setToken(tokenData.token);
  const gameData = await API().post("/join", { id, user, isNew });
  return gameData;
};

const isCreator = (game, auth) => game.meta && game.meta.gameCreator.id === auth.user.uid;

const isActivePlayer = (game, auth) =>
  game.state &&
  game.state.activePlayers.length > 0 &&
  game.state.activePlayers.find(p => p.uid === auth.user.uid) &&
  game.state.currentPlayer === game.state.activePlayers.find(p => p.uid === auth.user.uid).playerId;

const getTotalDiscoveredElementsByType = (game, type) => {
  const playerDiscovered = game.state.discovered[game.thisPlayer.id];
  const allElementsKnownToUser = playerDiscovered
    ? [...game.state.discovered.common, ...playerDiscovered]
    : game.state.discovered.common;
  return allElementsKnownToUser ? game.story[type].filter(element => allElementsKnownToUser.find(e => e.id === element.id)) : [];
};

const getCommonDiscoveredElementsByType = (game, type) => {
  return game.state.discovered.common
    ? game.story[type].filter(element => game.state.discovered.common.find(e => e.id === element.id))
    : [];
};

const getPrivateDiscoveredElementsByType = (game, type) => {
  return game.thisPlayer && game.state.discovered[game.thisPlayer.id]
    ? game.story[type].filter(element => game.state.discovered[game.thisPlayer.id].find(e => e.id === element.id))
    : [];
};

const getCluesByElement = (clues, id) => {
  return clues ? clues.filter(clue => clue.elements.find(el => el.id === id)) : [];
};

const leaveGame = () => {
  const resetGame = {
    connected: [],
    meta: null,
    state: null,
    story: null,
    storyMeta: null
  };
  return resetGame;
};

export {
  joinRoom,
  isCreator,
  isActivePlayer,
  getTotalDiscoveredElementsByType,
  getCommonDiscoveredElementsByType,
  getPrivateDiscoveredElementsByType,
  getCluesByElement,
  leaveGame
};
