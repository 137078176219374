export default store => {
  store.on("@init", () => ({
    auth: {
      logged: null,
      user: {}
    }
  }));

  store.on("auth/setLogged", ({ auth }, logged) => {
    return { auth: { ...auth, logged: logged } };
  });

  store.on("auth/setUser", ({ auth }, user) => {
    return { auth: { ...auth, user: user } };
  });
};
