export default store => {
  store.on("@init", () => ({
    error: {
      visible: false,
      text: ""
    }
  }));

  store.on("error/showError", ({ error }, payload) => {
    const ret = { ...error, visible: payload };
    return { error: ret };
  });

  store.on("error/setError", ({ error }, payload) => {
    const ret = { ...error, visible: payload.visible, text: payload.text };
    return { error: ret };
  });
};
