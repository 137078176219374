import React, { useState } from "react";
import withStyles from "react-jss";
import { useStoreon } from "storeon/react";

import GameCreate from "modules/Main/GameCreate/GameCreate";
import GameContinue from "modules/Main/GameContinue/GameContinue";
import GameJoin from "modules/Main/GameJoin/GameJoin";
import Game from "modules/Main/Game/Game";

import { AuthLogout } from "services/dbService";
import { leaveGame } from "services/gameService";
import { commonStyles, colors } from "services/theme";
import API from "services/api";

const styles = {
  ...commonStyles,
  dialArea: {
    position: "fixed",
    right: 0,
    bottom: 60,
    zIndex: 999
  },
  speedDial: {
    position: "fixed",
    right: 0,
    bottom: 0,
    zIndex: 199
  },
  roundIcon: {
    position: "relative",
    border: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    outline: "none !important",
    cursor: "pointer",
    borderRadius: "50%",
    margin: 15,
    boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)"
  },
  iconToShow: {
    opacity: 0,
    transform: "scale(0.5)",
    transition: "transform 0.7s"
  },
  iconShow: {
    opacity: 1,
    transform: "scale(1) !important"
  },
  roundLabel: {
    position: "absolute",
    right: "100%",
    marginRight: "8px",
    width: "max-content",
    fontSize: "0.7em",
    padding: "0 10px",
    borderRadius: "15px"
  }
};

export default withStyles(styles)(({ classes }) => {
  const { dispatch, ui, auth, game } = useStoreon("ui", "auth", "game");
  const [topAreaVisible, setTopAreaVisible] = useState(false);
  const [gameCode, setGameCode] = useState(false);

  const backToHome = () => {
    if (game && game.story && game.story.name) {
      if (window.confirm("Are you sure you want to leave the game?")) {
        const resetState = leaveGame();

        dispatch("game/setState", { newState: resetState, uid: auth.user.uid });
        dispatch("ui/setScreen", "Home");
      }
    } else {
      dispatch("ui/setScreen", "Home");
    }
    setTopAreaVisible(false);
  };

  const toggleCode = async () => {
    API().message(dispatch, { uid: auth.user.uid, toggleCode: true });
  };

  const toggleMusic = async () => {
    API().message(dispatch, { uid: auth.user.uid, toggleMusic: true });
  };

  const RoundIcon = ({ size = 50, color = "red", fontSize = "1rem", onClick, icon, label = null }) => (
    <button
      onClick={onClick}
      className={`${classes.roundIcon}`}
      style={{
        width: size,
        height: size,
        fontSize,
        background: colors[color].main,
        color: colors[color].secondary
      }}
    >
      {label && (
        <span
          className={classes.roundLabel}
          style={{
            height: size - 20,
            top: 10,
            background: colors[color].main,
            color: colors[color].secondary,
            lineHeight: `${size - 20}px`
          }}
        >
          {label}
        </span>
      )}
      {icon}
    </button>
  );

  const Burger = () => (
    <div className={`burger ${topAreaVisible ? "burger_open" : ""}`}>
      <div className="bar1"></div>
      <div className="bar2"></div>
      <div className="bar3"></div>
    </div>
  );

  return (
    <main className={`${classes.container} ${classes.bgMenu} ${classes.pb2}`}>
      {ui.appScreen === "Home" && (
        <div className={classes.fontLarger1}>
          <div className={`${classes.innerContainer}`}>
            <h1 className={`${classes.bigHeader} ${classes.mt1} ${classes.mb2}`}>
              Nameless
              <br />
              Noir
            </h1>
          </div>
          <button
            onClick={() => dispatch("ui/setScreen", "GameCreate")}
            className={`${classes.marginAuto} ${classes.link} ${classes.block}`}
          >
            <h2>Create Game</h2>
          </button>
          <button
            onClick={() => dispatch("ui/setScreen", "GameContinue")}
            className={`${classes.marginAuto} ${classes.link} ${classes.block}`}
          >
            <h2>Continue Game</h2>
          </button>
          <button
            onClick={() => dispatch("ui/setScreen", "GameJoin")}
            className={`${classes.marginAuto} ${classes.link} ${classes.block} ${classes.mb3}`}
          >
            <h2>Join Game</h2>
          </button>
        </div>
      )}

      {ui.appScreen === "GameCreate" && <GameCreate />}
      {ui.appScreen === "GameContinue" && <GameContinue />}
      {ui.appScreen === "GameJoin" && <GameJoin />}
      {ui.appScreen === "Game" && <Game setGameCode={setGameCode} />}

      {topAreaVisible && (
        <div className={`${classes.dialArea}`}>
          {gameCode && (
            <React.Fragment>
              <div className={`${classes.iconToShow} ${topAreaVisible ? classes.iconShow : ""}`}>
                <RoundIcon onClick={toggleCode} icon="C" color="light" label={`Show / Hide Code (${gameCode})`}></RoundIcon>
              </div>
              <div className={`${classes.iconToShow} ${topAreaVisible ? classes.iconShow : ""}`}>
                <RoundIcon onClick={toggleMusic} icon="M" color="light" label={`Music On/Off`}></RoundIcon>
              </div>
            </React.Fragment>
          )}
          <div className={`${classes.iconToShow} ${topAreaVisible ? classes.iconShow : ""}`}>
            <RoundIcon onClick={backToHome} icon="H" color="light" label="Back to Home"></RoundIcon>
          </div>
          <div className={`${classes.iconToShow} ${topAreaVisible ? classes.iconShow : ""}`}>
            <RoundIcon
              onClick={() => AuthLogout()}
              icon="L"
              color="light"
              label="Logout"
              show={topAreaVisible ? true : false}
            ></RoundIcon>
          </div>
          <div className={`${classes.iconToShow} ${topAreaVisible ? classes.iconShow : ""}`}>
            <RoundIcon
              onClick={() => window.location.reload(true)}
              icon="R"
              color="light"
              label="Reload"
              show={topAreaVisible ? true : false}
            ></RoundIcon>
          </div>
        </div>
      )}
      <div className={classes.speedDial}>
        <RoundIcon onClick={() => setTopAreaVisible(!topAreaVisible)} icon={<Burger />} show={true}></RoundIcon>
      </div>
    </main>
  );
});
