import React from "react";
import ReactDOM from "react-dom";

import { createStoreon } from "storeon";
import { StoreContext } from 'storeon/react';
import authStore from "stores/auth";
import errorStore from "stores/error";
import gameStore from "stores/game";
import uiStore from "stores/ui";
import App from "modules/App";

const store = createStoreon([
  authStore,
  errorStore,
  gameStore,
  uiStore,
]);

window.addEventListener("load", function() {
  // Add original push state
  window.history.pushState({}, "");
});

window.addEventListener("popstate", function(event) {
  if (!window.confirm("Are you sure you want to exit?")) {
    window.history.pushState({}, "");
  }
});

const rootElement = document.getElementById("root");
ReactDOM.render(
  <StoreContext.Provider value={store}>
    <App />
  </StoreContext.Provider>,
  rootElement
);
