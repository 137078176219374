import React, { useEffect, useState } from "react";
import withStyles from "react-jss";
import { useStoreon } from "storeon/react";

import { commonStyles } from "services/theme";
import API from "services/api";

const styles = {
  ...commonStyles
};

export default withStyles(styles)(({ classes, userIsCreator }) => {
  /* eslint-disable no-unused-vars */
  const { dispatch, game, auth, ui } = useStoreon("game", "auth", "ui");
  const [active, setActive] = useState(true);
  /* eslint-enable no-unused-vars */

  useEffect(() => {
    setActive(true);
    dispatch("ui/setLoading", false);

    return () => {
      setActive(true);
      dispatch("ui/setLoading", false);
    };
  }, [game.state, dispatch]);

  const clickContinue = () => {
    setActive(false);
    API().message(dispatch, { uid: auth.user.uid, introNext: true });
  };

  return (
    <React.Fragment>
      <div className={`${classes.innerContainer2}`}>
        <h1 className={`${classes.bigHeader} ${classes.mt1} ${classes.mb2}`}>Intro</h1>

        <div className={`${classes.mb2} ${classes.textLeft}`}>{game.story.intro[game.state.phaseData.introStep].text}</div>
        {userIsCreator && !ui.loading && active && (
          <button onClick={clickContinue} className={`${classes.margined1} ${classes.btn}`}>
            Continue
          </button>
        )}
      </div>
    </React.Fragment>
  );
});
