import React from "react";
import withStyles from "react-jss";

import { useStoreon } from "storeon/react";

import { AuthSignin } from "services/dbService";
import { useInputValue } from "services/hooks";
import { commonStyles, textColors } from "services/theme";

const styles = {
  ...textColors,
  ...commonStyles
};

export default withStyles(styles)(({ classes, setShowRegister }) => {
  /* eslint-disable no-unused-vars */
  const { ...email } = useInputValue("");
  const { ...password } = useInputValue("");

  const { dispatch, ui, error, auth } = useStoreon("ui", "error", "auth");
  /* eslint-enable no-unused-vars */

  const SubmitLogin = e => {
    e.preventDefault();
    dispatch("ui/setLoading", true);
    AuthSignin({ email: email.value, password: password.value })
      .then(async usr => {
        window.location.reload();
        // TODO: this causes error for state in unmounted component: fix as it is better to not refresh page.
      })
      .catch(err => {
        dispatch("ui/setLoading", false);
        dispatch("error/setError", { visible: true, text: err.message });
      });
  };

  return (
    <main className={`${classes.container} ${classes.bgMenu}`}>
      <div className={`${classes.innerContainer}`}>
        <h1 className={`${classes.redTxt} ${classes.bigHeader} ${classes.mt1} ${classes.mb2}`}>
          Nameless
          <br />
          Noir
        </h1>
        <form onSubmit={SubmitLogin} className={`${classes.pb2}`}>
          <input
            {...email}
            id="email"
            name="email"
            autoComplete="email"
            autoFocus
            required
            placeholder="email address"
            className={classes.mb1}
          />
          <input
            {...password}
            name="password"
            type="password"
            id="password"
            autoComplete="current-password"
            required
            placeholder="password"
          />
          <button type="submit" className={`${classes.btn} ${classes.mt3} ${classes.mb3}`}>
            LOGIN
          </button>
        </form>

        <div className={`${classes.mt2} ${classes.mb3}`}>
          Don't have an account?{" "}
          <button onClick={() => setShowRegister(true)} className={`${classes.link}`}>
            Register here
          </button>
        </div>
      </div>
    </main>
  );
});
