import React, { useEffect } from "react";
import withStyles from "react-jss";
import { useStoreon } from "storeon/react";

import BlockTexts from "modules/Main/Game/_components/BlockTexts";
import { isActivePlayer } from "services/gameService";
import { commonStyles } from "services/theme";
import API from "services/api";

const styles = {
  ...commonStyles
};

export default withStyles(styles)(({ classes }) => {
  /* eslint-disable no-unused-vars */
  const { dispatch, game, auth, ui } = useStoreon("game", "auth", "ui");
  /* eslint-enable no-unused-vars */

  useEffect(() => {
    dispatch("ui/setLoading", false);
    if (game.state.phaseData.blockData.blockIndex) {
      window.scroll({ top: 0, left: 0, behavior: "smooth" });
    }

    return () => {
      dispatch("ui/setLoading", false);
    };
  }, [game.state.phaseData.blockData.blockIndex, dispatch]);

  const clickContinue = () => {
    dispatch("ui/setLoading", true);
    API().message(dispatch, { uid: auth.user.uid, locationVisitNext: true });
  };

  return (
    <React.Fragment>
      {game.state && game.state.phaseData && (
        <div>
          <h3>{game.state.phaseData.location.name}</h3>

          {game.state.phaseData.private &&
            game.state.phaseData.private[game.thisPlayer.id] &&
            game.state.phaseData.private[game.thisPlayer.id].map(privateBlock => (
              <BlockTexts
                key={`private_${privateBlock.blockIndex}`}
                blockParent={game.state.phaseData.location}
                blockIndex={privateBlock.blockIndex}
                isPrivate={true}
              />
            ))}

          <BlockTexts blockParent={game.state.phaseData.location} blockIndex={game.state.phaseData.blockData.blockIndex} />

          {isActivePlayer(game, auth) && !ui.loading && (
            <button onClick={clickContinue} className={`${classes.margined1} ${classes.btn}`}>
              Continue
            </button>
          )}
        </div>
      )}
    </React.Fragment>
  );
});
