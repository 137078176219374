import React, { useEffect } from "react";
import withStyles from "react-jss";
import { useStoreon } from "storeon/react";

import BlockTexts from "modules/Main/Game/_components/BlockTexts";
import { commonStyles } from "services/theme";
import API from "services/api";

const styles = {
  ...commonStyles
};

export default withStyles(styles)(({ classes, userIsCreator }) => {
  /* eslint-disable no-unused-vars */
  const { dispatch, game, auth, ui } = useStoreon("game", "auth", "ui");
  /* eslint-enable no-unused-vars */

  useEffect(() => {
    dispatch("ui/setLoading", false);
    if (game.state.phaseData.blockData.blockIndex) {
      window.scroll({ top: 0, left: 0, behavior: "smooth" });
    }

    return () => {
      dispatch("ui/setLoading", false);
    };
  }, [game.state.phaseData.blockData.blockIndex, dispatch]);

  const clickContinue = () => {
    dispatch("ui/setLoading", true);
    API().message(dispatch, { uid: auth.user.uid, eventChoiceResultNext: true });
  };

  return (
    <div className={`${classes.innerContainer2}`}>
      <h1 className={`${classes.bigHeader} ${classes.mt1} ${classes.mb1}`}>{game.state.phaseData.event.name}</h1>
      <h2>{game.state.phaseData.choice.buttonTxt}</h2>

      {game.state.phaseData && (
        <BlockTexts blockParent={game.state.phaseData.choice} blockIndex={game.state.phaseData.blockData.blockIndex} />
      )}

      {userIsCreator && !ui.loading && (
        <button onClick={clickContinue} className={`${classes.margined1} ${classes.btn}`}>
          Continue
        </button>
      )}
    </div>
  );
});
