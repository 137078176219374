import React from "react";
import withStyles from "react-jss";
import { useStoreon } from "storeon/react";

import { commonStyles } from "services/theme";
import API from "services/api";

const styles = {
  ...commonStyles
};

export default withStyles(styles)(({ classes, userIsCreator }) => {
  /* eslint-disable no-unused-vars */
  const { dispatch, game, auth } = useStoreon("game", "auth");
  /* eslint-enable no-unused-vars */

  const gameOver = () => {
    API().message(dispatch, { uid: auth.user.uid, eventRecapAction: "end" });
    dispatch("ui/setScreen", "Home");
  };

  return (
    <div className={`${classes.innerContainer2}`}>
      <h1 className={`${classes.bigHeader} ${classes.mt1} ${classes.mb1}`}>Event Recap</h1>
      <h5>-Not yet impelmented-</h5>
      {game.state.phaseData && (
        <div>
          {game.state.phaseData.choice.isEnd && !game.state.phaseData.choice.canUndo && (
            <div>
              <h1>GAME OVER</h1>
              <button onClick={gameOver} className={`${classes.margined1} ${classes.btn}`}>
                Leave Game
              </button>
            </div>
          )}
          {game.state.phaseData.choice.isEnd && game.state.phaseData.choice.canUndo && (
            <div>
              <h1>GAME OVER</h1>
              {userIsCreator && (
                <button
                  onClick={() => API().message(dispatch, { uid: auth.user.uid, eventRecapAction: "undo" })}
                  className={`${classes.margined1} ${classes.btn}`}
                >
                  Make another choice
                </button>
              )}
            </div>
          )}
          {game.state.phaseData.choice.isEnd === false && userIsCreator && (
            <button
              onClick={() => API().message(dispatch, { uid: auth.user.uid, eventRecapAction: "continue" })}
              className={`${classes.margined1} ${classes.btn}`}
            >
              Continue
            </button>
          )}
        </div>
      )}
    </div>
  );
});
