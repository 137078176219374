import React, { useState, useEffect } from "react";
import withStyles from "react-jss";
import { useStoreon } from "storeon/react";

import { getPublishedStories, createNewGame } from "services/dbService";

import { commonStyles } from "services/theme";

const styles = {
  ...commonStyles
};

export default withStyles(styles)(({ classes }) => {
  /* eslint-disable no-unused-vars */
  const [stories, setStories] = useState([]);

  const { dispatch, auth } = useStoreon("auth");
  /* eslint-enable no-unused-vars */

  useEffect(() => {
    dispatch("ui/setLoading", true);
    getPublishedStories().then(publishedStories => {
      setStories(publishedStories);
      dispatch("ui/setLoading", false);
      console.log(publishedStories);
    });
  }, [dispatch]);

  // TODO: THIS SHOULD BE MOVED TO SERVER
  const createGame = gameData => {
    const now = new Date();
    const story = gameData.json && JSON.parse(gameData.json);
    delete gameData.json;
    const storyMeta = gameData;

    const data = {
      story,
      storyMeta,
      meta: {
        createdAt: now.toString(),
        updatedAt: now.toString(),
        gameCreator: {
          id: auth.user.uid,
          email: auth.user.email,
          displayName: auth.user.displayName
        }
      },
      connected: [],
      state: {
        round: 1,
        started: false,
        history: [],
        journal: {
          clues: [],
          objectives: [],
          completes: [],
          privates: {}
        }
      }
    };

    dispatch("ui/setLoading", true);
    createNewGame(auth.user.uid, data)
      .then(gameID => {
        dispatch("ui/setLoading", false);
        dispatch("ui/setRoomOptions", { id: gameID, method: "create" });
        dispatch("ui/setScreen", "Game");
      })
      .catch(error => {
        dispatch("ui/setLoading", false);
        dispatch("error/setError", { visible: true, text: error.message });
      });
  };

  return (
    <React.Fragment>
      <h1 className={`${classes.bigHeader} ${classes.mt1} ${classes.mb1}`}>Available Stories</h1>
      <button onClick={() => dispatch("ui/setScreen", "Home")} className={`${classes.btn} ${classes.btnSmall} ${classes.mb1}`}>
        Back to Home
      </button>
      <div className={classes.container}>
        {stories &&
          stories.map(story => (
            <div key={story.id} className={`${classes.box} ${classes.mb1}`}>
              <h3>{story.name}</h3>
              <p className={classes.smaller}>
                <em>{story.description}</em>
              </p>
              <div>
                Players: {story.players.min || 1} - {story.players.max}
              </div>
              <div>
                <button onClick={() => createGame(story)} className={`${classes.btn} ${classes.btn80} ${classes.mt1}`}>
                  Create Game
                </button>
              </div>
            </div>
          ))}
      </div>
    </React.Fragment>
  );
});
