import React, { useState, useEffect, useCallback } from "react";
import withStyles from "react-jss";
import { useStoreon } from "storeon/react";
import * as serviceWorker from "serviceWorker";

import { AuthCheck } from "services/dbService";
import Auth from "modules/Auth";
import Main from "modules/Main/Main";
import { commonStyles } from "services/theme";

import "./Normalize.css";
import "./App.css";

const styles = {
  ...commonStyles,
  notificationPop: {
    position: "fixed",
    bottom: 0,
    left: 0,
    width: "100vw",
    padding: "1em 2em",
    background: "#eee",
    color: "#333"
  },
  notificationPopClose: {
    position: "absolute",
    bottom: "105%",
    right: "0"
  }
};

export default withStyles(styles)(({ classes }) => {
  const { dispatch, ui, error, auth } = useStoreon("ui", "error", "auth");
  const [showUpdate, setShowUpdate] = useState(false);

  const checkAuthStatus = useCallback(async () => {
    dispatch("ui/setLoading", true);
    const user = await AuthCheck();
    dispatch("auth/setUser", user);
    dispatch("ui/setLoading", false);
    dispatch("auth/setLogged", user ? true : false);
  }, [dispatch]);

  useEffect(() => {
    checkAuthStatus();
  }, [checkAuthStatus]);

  // enable service workers
  if (process.env.NODE_ENV === "production") {
    serviceWorker.register({
      onUpdate: installingWorker => {
        console.log(installingWorker);
        setShowUpdate(true);
      }
    });
  }

  return (
    <div>
      {ui.loading === true && (
        <div className="full-over">
          <div className={classes.fontLarger1}>...LOADING...</div>
        </div>
      )}
      {showUpdate && <div id="snackbar">A new version of this app is available. Reloading...</div>}

      {auth.logged === true && <Main />}
      {auth.logged === false && <Auth />}

      {error.visible && (
        <div className={classes.notificationPop}>
          <div>{error.text}</div>
          <button
            onClick={() => dispatch("error/setError", { visible: false, text: "" })}
            className={`${classes.notificationPopClose} ${classes.btn} ${classes.bgDark}`}
          >
            X
          </button>
        </div>
      )}
    </div>
  );
});
