import React, { useState, useEffect } from "react";
import withStyles from "react-jss";
import { useStoreon } from "storeon/react";

import { getCreatedGames, deleteExistingGame } from "services/dbService";
import { commonStyles } from "services/theme";
import Carousel from "modules/Main/Game/_components/Carousel";

const styles = {
  ...commonStyles
};

export default withStyles(styles)(({ classes }) => {
  const [games, setGames] = useState([]);
  const { dispatch, auth } = useStoreon("auth");

  useEffect(() => {
    dispatch("ui/setLoading", true);
    getCreatedGames(auth.user.uid).then(userCreatedGames => {
      setGames(userCreatedGames);
      dispatch("ui/setLoading", false);
      console.log(userCreatedGames);
    });
  }, [auth.user.uid, dispatch]);

  const userContinueGame = gameID => {
    // FETCH GAME DATA THEN
    dispatch("ui/setRoomOptions", { id: gameID, method: "create" });
    dispatch("ui/setScreen", "Game");
  };

  const userDeleteGame = gameID => {
    if (window.confirm("Are you sure? Game in progress will be deleted.")) {
      dispatch("ui/setLoading", true);
      deleteExistingGame(auth.user.uid, gameID)
        .then(done => {
          getCreatedGames(auth.user.uid).then(userCreatedGames => {
            setGames(userCreatedGames);
            dispatch("ui/setLoading", false);
          });
        })
        .catch(error => {
          dispatch("ui/setLoading", false);
          dispatch("error/setError", { visible: true, text: error.message });
        });
    }
  };

  return (
    <React.Fragment>
      <h1 className={`${classes.bigHeader} ${classes.mt1} ${classes.mb1}`}>Continue a game</h1>
      <button onClick={() => dispatch("ui/setScreen", "Home")} className={`${classes.btn} ${classes.btnSmall} ${classes.mb1}`}>
        Back to Home
      </button>
      {games && (
        <Carousel
          perPage={2}
          slides={games.map(game => (
            <div key={game.gameID} className={`${classes.box}`}>
              <h3>{game.storyName}</h3>
              {game.details && (
                <div>
                  <div>Created: {new Date(game.details.meta.createdAt).toDateString()}</div>
                  <div>Joined Players: {game.details.state.activePlayers && game.details.state.activePlayers.length}</div>
                  <div>Round: {game.details.state.round}</div>
                  <button
                    onClick={() => userContinueGame(game.gameID)}
                    className={`${classes.btn} ${classes.mt1} ${classes.btn80} ${classes.btnSecondary}`}
                  >
                    Continue
                  </button>
                  <button
                    onClick={() => userDeleteGame(game.gameID)}
                    className={`${classes.btn} ${classes.btnSmall} ${classes.mt1} ${classes.mt2}`}
                  >
                    Delete Game
                  </button>
                </div>
              )}
            </div>
          ))}
        ></Carousel>
      )}
    </React.Fragment>
  );
});
