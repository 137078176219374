import React from "react";
import withStyles from "react-jss";
import { useStoreon } from "storeon/react";

import { commonStyles } from "services/theme";
import API from "services/api";

const styles = {
  ...commonStyles
};

export default withStyles(styles)(({ classes }) => {
  /* eslint-disable no-unused-vars */
  const { dispatch, game, auth } = useStoreon("game", "auth");
  /* eslint-enable no-unused-vars */

  return (
    <div className={`${classes.innerContainer}`}>
      <h1 className={`${classes.bigHeader} ${classes.mt1} ${classes.mb1}`}>What would you like to do?</h1>
      {game.state.phaseData &&
        game.state.phaseData.event.choices.map((choice, choice_index) => (
          <button
            key={`choice_${choice_index}`}
            onClick={() => API().message(dispatch, { uid: auth.user.uid, eventVoteChoice: choice_index })}
            className={`${classes.margined1} ${classes.btn}`}
          >
            {choice.buttonTxt}
          </button>
        ))}
    </div>
  );
});
