import { extend } from "services/utilities";

export default store => {
  store.on("@init", () => ({
    game: {
      connected: [],
      meta: null,
      state: null,
      story: null,
      storyMeta: null
    }
  }));

  store.on("game/setState", ({ game }, payload) => {
    const { newState, uid } = payload;

    const story = game.story ? game.story : newState.story;
    if (story && newState && newState.state) {
      const thisActivePlayer = newState.state.activePlayers && newState.state.activePlayers.find(p => p.uid === uid);
      if (thisActivePlayer) {
        newState.thisPlayer = story.players.find(p => p.id === thisActivePlayer.playerId);
      } else {
        newState.thisPlayer = null;
      }
    }

    /*if (newState.story) {
      ret.story = newState.story;
      ret.meta = newState.meta;
      ret.storyMeta = newState.storyMeta;
    }
    if (newState.connected) {
      ret.connected = newState.connected;
    }
    ret.state = newState.state;

    return { game: { ...game, ...ret } };
    */

    extend(game, newState);
    return { game };
  });
};
