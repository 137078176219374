import React from "react";
import withStyles from "react-jss";
import { useStoreon } from "storeon/react";

import { commonStyles } from "services/theme";
import API from "services/api";

const styles = {
  ...commonStyles
};

export default withStyles(styles)(({ classes, code, userIsCreator }) => {
  /* eslint-disable no-unused-vars */
  const { dispatch, game, auth } = useStoreon("game", "auth");
  /* eslint-enable no-unused-vars */

  //TODO: move to other components
  const selectPlayer = async playerId => {
    API().message(dispatch, { uid: auth.user.uid, selectPlayer: playerId });
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  };

  const startGame = async () => {
    //TODO: checks on min / max players joined and selected.
    // Also on button (disabled)
    API().message(dispatch, { uid: auth.user.uid, startGame: true });
  };

  return (
    <React.Fragment>
      <div className={`${classes.innerContainer}`}>
        <h1 className={`${classes.bigHeader} ${classes.mt1} ${classes.mb1}`}>{game.storyMeta && game.storyMeta.name}</h1>
        {game.state &&
          userIsCreator &&
          !game.state.started &&
          game.state.activePlayers &&
          game.connected.length === game.state.activePlayers.length && (
            <button onClick={startGame} className={`${classes.btn}`}>
              START GAME
            </button>
          )}
        <h4>CODE : {code}</h4>
        <p>
          <a href={`https://board.namelessnoir.com/#${code}`} target="_blank" rel="noopener noreferrer">
            https://board.namelessnoir.com/#{code}
          </a>
        </p>
        <h4>Connected Players: {game.connected && game.connected.length}</h4>
      </div>

      {game.story &&
        game.state &&
        game.story.players &&
        game.story.players.map(player => (
          <div key={player.id} className={`${classes.box} ${classes.mb1}`}>
            <h3>{player.name}</h3>
            {game.state.activePlayers &&
              game.state.activePlayers
                .filter(sel => sel.playerId === player.id)
                .map(ch => (
                  <div key={ch.uid}>
                    {game.connected.find(p => p.uid === ch.uid) && game.connected.find(p => p.uid === ch.uid).email}
                  </div>
                ))}
            <p>{player.description}</p>
            {(!game.state.selectedPlayers || game.state.selectedPlayers.find(sel => sel === player.id)) && (
              <button onClick={() => selectPlayer(player.id)} className={`${classes.btn} ${classes.btn80} ${classes.mt1} `}>
                Select
              </button>
            )}
          </div>
        ))}
    </React.Fragment>
  );
});
