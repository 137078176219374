import Firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";
// import "firebase/storage";

const config = {
  apiKey: "AIzaSyDbwMPhMogF6Awq9Zar1d-VH7jMjDfmM0o",
  authDomain: "nameless-noir-create.firebaseapp.com",
  databaseURL: "https://nameless-noir-create.firebaseio.com",
  projectId: "nameless-noir-create",
  storageBucket: "nameless-noir-create.appspot.com",
  messagingSenderId: "633627518880"
};

const FirebaseApp = Firebase.initializeApp(config);
const FirebaseAuth = FirebaseApp.auth();
// const FirebaseRealtime = Firebase.database();
const FirebaseDb = FirebaseApp.firestore(); // https://firebase.google.com/docs/firestore/manage-data/enable-offline
// FirebaseDb.settings({ timestampsInSnapshots: true });
/*const FirebaseStorage = {
  init(uid) {
    this.storage = FirebaseApp.storage();
    this.ref = this.storage.ref();
    this.images = this.ref.child(`${uid}/images`);
    this.videos = this.ref.child(`${uid}/videos`);
  },
  images: null,
  videos: null,
  storage: null,
  ref: null
};*/

const batch = FirebaseDb.batch();

const dbUsers = FirebaseDb.collection("users");
const dbPublished = FirebaseDb.collection("published");
const dbRoomCodes = FirebaseDb.collection("roomCodes");

FirebaseAuth.setPersistence("local");

export {
  Firebase,
  FirebaseApp,
  FirebaseAuth,
  FirebaseDb,
  // FirebaseRealtime,
  batch,
  dbUsers,
  dbPublished,
  dbRoomCodes
  //FirebaseStorage,
};

/* db.enablePersistence().then(function() {
  // Initialize Cloud Firestore through firebase
  var db = firebase.firestore();
}).catch(function(err) {
  if (err.code == 'failed-precondition') {
    // Multiple tabs open, persistence can only be enabled
    // in one tab at a a time.
    // ...
  } else if (err.code == 'unimplemented') {
    // The current browser does not support all of the
    // features required to enable persistence
    // ...
  }
}); */
// https://firebase.google.com/docs/firestore/quickstart?authuser=0
